// totalSupply

import { get, method, isEmpty } from "lodash";
import { useEffect, useState } from "react";

export const useReadContractField = (
  readContracts,
  contractName,
  methodName
) => {
  const [res, setRes] = useState(null);
  useEffect(() => {
    const callMethod = async () => {
      if (!isEmpty(readContracts)) {
        const m = get(readContracts, `${contractName}.${methodName}`);
        if (m !== undefined) {
          const r = await m();

          setRes(r.toNumber());
        }
      }
    };
    callMethod();
  }, [readContracts, contractName, methodName]);

  return res;
};
