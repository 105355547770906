export default function Example() {
  return (
    <footer className="bg-gray-800" aria-labelledby="footer-heading">
      <div className="border-t border-gray-700 pt-8 pb-8 flex justify-center text-center">
        <p className="mt-8 text-base text-xl text-gray-400 md:mt-0 md:order-1">
          Created by{" "}
          <a
            className="text-underline"
            target="_blank"
            href="https://www.northwestnfts.com"
          >
            NorthwestNFTs
          </a>
          .
        </p>
      </div>
    </footer>
  );
}
