/* This example requires Tailwind CSS v2.0+ */
export default function Community() {
  return (
    <div className="bg-gray-800	text-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <h2 className="max-w-md mx-auto text-3xl font-extrabold  text-center lg:max-w-xl lg:text-left lg:mt-8">
            <a id="community">Join the Sky Castles Community</a>
          </h2>
          <div className="flow-root self-center mt-8 lg:mt-0">
            <div className="-mt-4 -ml-8 grid grid-cols-2 gap-8 flex flex-wrap justify-evenly	 lg:-ml-4">
              <div className="mt-2 ml-8 flex flex-grow flex-shrink-0 justify-between	 lg:flex-grow-0 lg:ml-4">
                <a
                  href="https://discord.gg/GjzwzAuHZr"
                  className=""
                  target="_blank"
                >
                  <img
                    className="h-12 inline-block"
                    src={`${process.env.PUBLIC_URL}/Discord-Logo+Wordmark-White.svg`}
                    alt="Discord"
                  />
                </a>
              </div>
              <div className="mt-2 ml-8 flex flex-grow flex-shrink-0 justify-between	 lg:flex-grow-0 lg:ml-4">
                <a
                  href="https://twitter.com/sky_castles_dcl"
                  className=""
                  target="_blank"
                >
                  <img
                    className="h-12 inline-block"
                    src={`${process.env.PUBLIC_URL}/Twitter-Circle-White.svg`}
                    alt="Twitter"
                  />
                  <span className="inline-block text-2xl align-middle	 ml-3 font-semibold	">
                    Twitter
                  </span>
                </a>
              </div>
              <div className="mt-2 ml-8 flex flex-grow flex-shrink-0 justify-between	 lg:flex-grow-0 lg:ml-4">
                <a
                  href="https://opensea.io/collection/sky-castles-of-decentraland"
                  className=""
                  target="_blank"
                >
                  <img
                    className="h-12 inline-block"
                    src={`${process.env.PUBLIC_URL}/OpenseaTransparentWhite.svg`}
                    alt="OpenSea"
                  />
                  <span className="inline-block text-2xl align-middle	 ml-3 font-semibold	">
                    OpenSea
                  </span>
                </a>
              </div>
              <div
                className=" ml-8 flex flex-grow flex-shrink-0 justify-between lg:flex-grow-0 lg:ml-4"
                style={{ width: "200px" }}
              >
                <a
                  href="https://etherscan.io/address/0x7160b3977cf8d2fd72d34e08ca710c5fb49949c4"
                  className=""
                  target="_blank"
                >
                  <span className="inline-block text-2xl align-middle ml-3 font-semibold	">
                    Verified on Etherscan
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
