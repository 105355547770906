import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronRightIcon } from "@heroicons/react/solid";
import * as cn from "classnames";
import * as styles from "../App.module.scss";
import { kSaleLive } from "../constants";
import { useAddress } from "../hooks/useAddress";

import { networkDisplayNames } from "../helpers/supportedNetworks";

const navigation = [
  { name: "Roadmap", href: "#roadmap" },
  // { name: "About the Sky Castles", href: "#about" },
  { name: "Community", href: "#community" },
];

export default function HomepageHero(props) {
  const { children, heroInfoSection, network, signer } = props;

  const signerAddress = useAddress(signer);

  const networkName = network ? network.name : "Loading";

  const networkId = network ? network.chainId : undefined;

  let walletInfo = null;
  if (kSaleLive) {
    if (!!signerAddress) {
      walletInfo = (
        <div className="hidden md:flex md:items-center md:hidden text-white">
          <span>
            Network:{" "}
            {networkId
              ? `${networkDisplayNames[networkName]} -`
              : "No connection"}
          </span>
          &nbsp;
          <span>{signerAddress}</span>
        </div>
      );
    } else {
      walletInfo = (
        <div className="hidden md:flex md:items-center md:space-x-6">
          <a
            href="#"
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
          >
            Connect Wallet
          </a>
        </div>
      );
    }
  }
  return (
    <div className="relative overflow-hidden">
      <Popover as="header" className="relative">
        <div className="bg-gray-900 pt-4 pb-4">
          <nav
            className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="#">
                  <span className="sr-only">Workflow</span>
                  <img
                    className="h-8 w-auto sm:h-10"
                    src={`${process.env.PUBLIC_URL}/Floaty_castle_icon_small.png`}
                    alt=""
                  />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-8 md:flex md:ml-10">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-base font-medium text-white hover:text-gray-300"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
            {walletInfo}
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top md:hidden"
          >
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt=""
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-6 px-5">
                  <a
                    href="#"
                    className="block text-center w-full py-3 px-4 rounded-md shadow bg-indigo-600 text-white font-medium hover:bg-indigo-700"
                  >
                    Start free trial
                  </a>
                </div>
                <div className="mt-6 px-5">
                  <p className="text-center text-base font-medium text-gray-500">
                    Existing customer?{" "}
                    <a href="#" className="text-gray-900 hover:underline">
                      Login
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      <main>
        <header className="relative flex items-center justify-center h-screen overflow-hidden">
          <div
            className={cn(
              "absolute z-30 p-3 bg-opacity-50 bg-black rounded-l -mt-4",
              styles.castlesheader
            )}
          >
            <h1 className={cn("text-white text-5xl", styles.castlesheadertext)}>
              Sky Castles of Decentraland
            </h1>
          </div>
          {heroInfoSection}
          <video
            autoPlay
            loop
            muted
            controls=""
            preload="none"
            className={cn(
              "absolute z-10 w-auto min-w-full min-h-full max-w-none",
              styles.skycastlesvideo
            )}
            style={{
              objectFit: "cover",
              width: "100%",
            }}
            poster={`${process.env.PUBLIC_URL}/CastlesFade.png`}
          >
            <source
              src={`${process.env.PUBLIC_URL}/CastlesFade.mp4`}
              type="video/mp4"
            />
            <source
              src={`${process.env.PUBLIC_URL}/CastlesFade.webm`}
              type="video/webm"
            />
            Your browser does not support the video tag.
          </video>
        </header>

        {/* More main page content here... */}
        {children}
      </main>
    </div>
  );
}
