import React, { useCallback, useState } from "react";
import { ethers } from "ethers";
import { isEmpty } from "lodash";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

import * as cn from "classnames";
import * as styles from "../App.module.scss";

import { useAddress } from "../hooks/useAddress";
import {
  generateMerkleTree,
  useWhitelistJson,
  useWhitelistIndex,
} from "../hooks/useWhitelistJson";
import { useReadContractField } from "../hooks/useReadContractField";

import { kSaleLive } from "../constants";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";

// const kOurChainId = 1; // 1
const kPrice = 0.05;
const kTotalAvailable = 500;
const kClaimsAvailable = 35;

const kMintContract = "ERC721InitMint";
const kTotalSupply = "totalSupply";
const kAllowedNetworks = [1, 3];

async function changeToHomestead(provider, updateNetwork) {
  try {
    await provider.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }],
    });
    updateNetwork();
  } catch (switchError) {
    console.error(switchError);
  }
}

export default function MintSection(props) {
  const {
    provider,
    setProvider,
    signer,
    updateSigner,
    network,
    updateNetwork,
    readContracts,
    writeContracts,
  } = props;
  const [currentAccount, setCurrentAccount] = useState(null);
  const [minted, setMinted] = useState(false);
  const [mintCount, setMintCount] = useState(1);
  const [declineWhitelist, setDeclineWhitelist] = useState(false);

  const networkName = network ? network.name : "Loading";

  const networkId = network ? network.chainId : undefined;

  const signerAddress = useAddress(signer);
  const whitelist = useWhitelistJson(provider);
  const whitelistIndex = useWhitelistIndex(whitelist, signerAddress);
  const totalSupply = useReadContractField(
    readContracts,
    kMintContract,
    kTotalSupply
  );
  const totalAvailableForClaim = kTotalAvailable - totalSupply;
  const totalAvailableForMint = totalAvailableForClaim - kClaimsAvailable;

  const requestAccess = useCallback(() => {
    function handleAccountsChanged(newAccounts) {
      if (newAccounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        console.log("Please connect to MetaMask.");
      } else if (newAccounts[0] !== currentAccount) {
        setCurrentAccount(newAccounts[0]);
        updateSigner();
      }
    }

    const requestAuth = async (web3Context) => {
      web3Context.provider
        .request({ method: "eth_requestAccounts" })
        .then(handleAccountsChanged)
        .catch((err) => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log("Please connect to MetaMask.");
          } else {
            console.error(err);
          }
        });
    };

    requestAuth(provider);
    // eslint-disable-next-line
  }, [provider, signerAddress, currentAccount, updateSigner]);

  const walletConnectAccess = useCallback(() => {
    const requestAuth = async (web3Context) => {
      const walletConnectProvider = new WalletConnectProvider({
        rpc: {
          1: `https://eth-mainnet.alchemyapi.io/v2/H5dlh4vYmmUikLTkHuyq8R3AyokJHudy`, // mainnet
          3: `https://eth-ropsten.alchemyapi.io/v2/b3YVxsSEil1a7ueTTzff74PjIkiGus_-`,
        },
        qrcode: true,
      });
      await walletConnectProvider.enable();
      const provider = new ethers.providers.Web3Provider(walletConnectProvider);

      setProvider(provider);
    };

    requestAuth();
  });

  const mint = (numToMint) => {
    const val = numToMint * kPrice;
    if (!isEmpty(writeContracts)) {
      writeContracts.OpenSale.mint(numToMint, {
        value: ethers.utils.parseUnits(val.toString()),
      })
        .then(() => {
          setMinted(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const claim = () => {
    const merkleTree = generateMerkleTree(whitelist);

    const proof = merkleTree.getHexProof(merkleTree.getLeaf(whitelistIndex));
    const claimAmount = whitelist.amounts[whitelistIndex];

    if (!isEmpty(writeContracts)) {
      writeContracts.MerkleWhitelist.claimWhitelist(
        whitelistIndex,
        1,
        claimAmount,
        proof
      )
        .then(() => {
          setMinted(true);
        })
        .catch((e) => {
          console.error("error", e);
        });
    }
  };

  let walletSection = null;
  if (!signerAddress) {
    walletSection = (
      <div>
        <button onClick={requestAccess} className={styles.connectWallet}>
          Connect Metamask Wallet
        </button>
        <button onClick={walletConnectAccess} className={styles.connectWallet}>
          Connect via Wallet Connect
        </button>
      </div>
    );
  } else if (kAllowedNetworks.indexOf(networkId) === -1) {
    walletSection = (
      <div>
        <button
          onClick={() => {
            changeToHomestead(provider, updateNetwork);
          }}
          className={styles.connectWallet}
        >
          Please switch to ethereum mainnet
        </button>
      </div>
    );
  } else if (totalAvailableForMint === 0) {
    walletSection = (
      <div className="mt-3 font-semibold">
        We sold out! Congratulations and thanks to everyone. See the collection
        on{" "}
        <a
          href="https://opensea.io/collection/sky-castles-of-decentraland"
          target="_blank"
        >
          OpenSea
        </a>
        .
      </div>
    );
  } else if (minted) {
    //
    walletSection = (
      <div className="mt-3 font-semibold">
        Congratulations! You've minted {mintCount} Sky Castles. You can view
        your castle on{" "}
        <a
          href="https://opensea.io/collection/sky-castles-of-decentraland"
          target="_blank"
        >
          OpenSea
        </a>
        .
      </div>
    );
  } else if (whitelistIndex !== -1 && !declineWhitelist) {
    //
    walletSection = (
      <div className="mt-3 font-semibold">
        <div>You're on the whitelist! Claim your free Sky Castle!</div>
        <span
          className="cursor-pointer text-base mt-3 block"
          onClick={() => {
            setDeclineWhitelist(true);
          }}
        >
          (Or, click here to pay to mint)
        </span>
        <button
          onClick={() => {
            claim();
          }}
          className={styles.connectWallet}
        >
          Claim my Sky Castle
        </button>
      </div>
    );
  } else {
    // check for claim
    // on whitelist check
    const whitelistNote =
      whitelistIndex !== -1 ? (
        <div
          className="cursor-pointer text-base mt-3"
          onClick={() => {
            setDeclineWhitelist(false);
          }}
        >
          (You're on the whitelist, click here to claim your castle.)
        </div>
      ) : null;
    // minting
    walletSection = (
      <>
        <div>
          <div className="mt-3">
            Price: {kPrice}
            {ethers.constants.EtherSymbol}
          </div>
          <div className="select-none	inline-block">
            <span
              className="inline-block cursor-pointer"
              onClick={() => {
                setMintCount(Math.max(mintCount - 1, 0));
              }}
            >
              <MinusCircleIcon width={30} className="inline-block" />
            </span>
            <span className="inline-block w-8 p-2 align-middle content-center">
              {mintCount}
            </span>
            <span
              className="inline-block cursor-pointer"
              onClick={() => {
                setMintCount(Math.min(mintCount + 1, totalAvailableForMint));
              }}
            >
              <PlusCircleIcon width={30} className="inline-block" />
            </span>
          </div>

          <button
            onClick={() => {
              mint(mintCount);
            }}
            className={cn("ml-4 select-none", styles.connectWallet)}
          >
            Mint tokens
          </button>
          {whitelistNote}
        </div>
      </>
    );
  }

  const contents = kSaleLive ? (
    walletSection
  ) : (
    <button className={styles.connectWallet}>Coming Soon</button>
  );

  return (
    <div
      className={cn(
        "z-30 p-5 bg-opacity-50 bg-black rounded-l text-white text-2xl ",
        styles.cta
      )}
    >
      <div>
        <span>Unique 3D floating castles, designed for Decentraland</span>
        <br />
        {contents}
      </div>
    </div>
  );
}
