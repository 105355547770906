// import { Fragment } from "react";
// import { Popover, Transition } from "@headlessui/react";
// import { MenuIcon, XIcon } from "@heroicons/react/outline";
// import { ChevronRightIcon } from "@heroicons/react/solid";
import {
  AnnotationIcon,
  GlobeAltIcon,
  LightningBoltIcon,
  MailIcon,
  ScaleIcon,
} from "@heroicons/react/outline";

/* This example requires Tailwind CSS v2.0+ */

const roadmapFeatures = [
  {
    id: 1,
    name: "One parcel Castle",
    description:
      "For one parcel of land, there will be a grounded castle. (See our prototype to the right.) Its majesty and style will make your neighbors envious.",
    icon: LightningBoltIcon,
  },
  {
    id: 3,
    name: "Four parcel floating castle",
    description:
      "With a four parcel lot, NFT holders will be able to use a scene file for a floating castle. The castle will include a teleporter up to the castle floor for easy access.",
    icon: LightningBoltIcon,
  },
  {
    id: 2,
    name: "Finite heights",
    description:
      "In the NFTs, some of the castles have infinitely tall Castles or foundations. Sadly, Decentraland does not accomodate infinite heights. Users with those castles will receive a design file with modifications made to fit within Decentraland.",
    icon: LightningBoltIcon,
  },
];

export default function Decentraland() {
  return (
    <div className="py-10 bg-gray-50 overflow-hidden lg:py-20 border-t border-gray-900">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl ">
        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <a id="roadmap">Decentraland Design Files</a>
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500 hidden">
            The Decentraland design files will be released when 20% of NFTs are
            minted. There are polygon and texture{" "}
            <a href="https://docs.decentraland.org/development-guide/scene-limitations/">
              limitations
            </a>{" "}
            for Decentraland scenes.
          </p>
        </div>
        <div className="relative mt-8 lg:mt-16 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <p className="mt-4 max-w-3xl mx-auto  text-2xl text-gray-900">
              The Decentraland design files will be released when 20% of NFTs
              are minted. There are polygon and texture{" "}
              <a
                className="underline"
                target="_blank"
                href="https://docs.decentraland.org/development-guide/scene-limitations/"
              >
                limitations
              </a>{" "}
              for Decentraland scenes.
            </p>
            <p className="mt-3 text-lg text-gray-500"></p>

            <dl className="mt-10 space-y-10">
              {roadmapFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white font-bold">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <img
              className="relative mx-auto"
              width={490}
              src={`${process.env.PUBLIC_URL}/decentraland_preview.png`}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
