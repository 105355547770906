export const networkUrls = {
  homestead: true,
  ropsten: true,
  matic: true,
};

export const networkDisplayNames = {
  homestead: "etherium mainnet",
  ropsten: "ropsten testnet",
  matic: "polygon mainnet",
};

export const polygonNetworkConfig = {
  chainName: "Polygon Mainnet",
  hexChainId: "0x89",
  chainId: 137,
  rpcUrls: ["https://polygon-rpc.com/"],
  blockExplorerUrls: ["https://polygonscan.com/"],
};
