import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { has } from "lodash";
import { useContractConfig } from "./hooks/useContractConfig";
import { useNetwork } from "./hooks/useNetwork";
import { useSigner } from "./hooks/useSigner";
import { useContractLoaderReload } from "./hooks/useContractLoaderReload";
// import { useContractLoader } from "eth-hooks";
import * as styles from "./App.module.scss";
import HomepageHero from "./components/HomepageHero";
import Roadmap from "./components/Roadmap";
import Decentraland from "./components/Decentraland";
import Community from "./components/Community";
import Footer from "./components/Footer";

import { contractAddressByNetwork } from "./helpers/contractAddresses";
import MintSection from "./components/MintSection";

const useProvider = (wndEthereum) => {
  const [provider, setProvider] = useState(null);

  const updateProvider = () => {
    let p = null;
    if (wndEthereum !== undefined) {
      p = new ethers.providers.Web3Provider(wndEthereum, "any");
      setProvider(p);
    }
    return p;
  };

  useEffect(() => {
    const p = updateProvider();
    if (p !== null) {
      p.on("network", () => {
        console.log("got network change");
        updateProvider();
      });
    }
  }, [wndEthereum]);

  return [provider, setProvider];
};

export function AppProvider(props) {
  const [provider, setProvider] = useProvider(window.ethereum);
  return <App provider={provider} setProvider={setProvider}></App>;
}

export function App(props) {
  const { provider, setProvider } = props;
  const [network, updateNetwork] = useNetwork(provider);

  const [signer, updateSigner] = useSigner(provider);
  const [EthNetworkName, setEthNetworkName] = useState("homestead");

  let networkToShow = network !== undefined ? network.name : EthNetworkName;

  const contractConfig = useContractConfig(provider, networkToShow);

  const [chainId, setChainId] = useState();
  useEffect(() => {
    if (network !== undefined) {
      setChainId(network.chainId);
    }
  }, [network]);

  const readContracts = useContractLoaderReload(
    provider,
    contractConfig,
    chainId
  );
  const writeContracts = useContractLoaderReload(
    signer,
    contractConfig,
    chainId
  );

  // WARNING
  return (
    <HomepageHero
      signer={signer}
      network={network}
      updateNetwork={updateNetwork}
      heroInfoSection={
        <MintSection
          signer={signer}
          provider={provider}
          setProvider={setProvider}
          updateSigner={updateSigner}
          network={network}
          updateNetwork={updateNetwork}
          writeContracts={writeContracts}
          readContracts={readContracts}
        ></MintSection>
      }
    >
      <Roadmap></Roadmap>
      <Decentraland></Decentraland>
      <Community></Community>
      <Footer></Footer>
    </HomepageHero>
  );
}
//
export default App;
